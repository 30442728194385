import * as React from "react"
import {motion, AnimatePresence, useAnimation} from "framer-motion";
import {useIsMobile} from "../utils";
import {Canvas} from '@react-three/fiber'
import Ring, {Box} from "./ring";
import './marquee.css';
import {Suspense, useEffect, useState} from "react";
import {PresentationControls} from "@react-three/drei";
import {Line} from "./service";
import {useInView} from "react-intersection-observer";


const MarqueeText = (props) => {
    const shift = props.shift || 0;
    const TextLine = () => (
        <div style={{ display: 'flex'}}>
            <h1>
                Pure
            </h1>
            <h1 style={{color: 'rgba(19,19,19,0.1)'}}>/</h1>
            <h1>
                Organic
            </h1>
            <h1 style={{color: 'rgba(19,19,19,0.1)'}}>/</h1>
            <h1>
                Fragrance Free
            </h1>
            <h1 style={{color: 'rgba(19,19,19,0.1)'}}>/</h1>
        </div>
);
    return (
        <div className="marquee">
            <motion.div
                className="track"
                // variants={marqueeVariants}
                animate={{
                    x: [-shift , -1897 - shift],
                }
                }
                transition={{duration: 15, repeat: Infinity, repeatType: 'loop', ease: 'linear'}}
            >
                <TextLine />
                <TextLine />
                <TextLine />
            </motion.div>
        </div>
    );
}

const MarqueeRing = (props) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 2,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            y: 70,
        },
    };
    const [ showRing, setShowRing ] = useState(false);
    useEffect(() => {
        if (inView) {
            setShowRing(true);
            controls.start("visible");
        }
    }, [controls, inView]);
    return (
      <motion.div
          ref={ref}
          variants={variants}
          animate={controls}
          initial="hidden"
          style={props.style}
      >
          {showRing && <Ring
              angle={props.angle}
              rotateParams={props.rotateParams}
              position={props.position}
          />}
      </motion.div>
    );
}


const Marquee = () => {

    const { screenWidth } = useIsMobile();

    return <div style={{position: 'relative'}}>
        <div style={{
            fontSize: 24,
            margin: '56px 84px',
        }}>
            / MARQUEE
        </div>
        <Line />
        <MarqueeText />
        <Line />
        <MarqueeText shift={300}/>
        <Line />
        <MarqueeRing
            position={[0, 0, 4.5]}
            rotateParams={[0.02, -0.04, -0.04]}
            style={{
            position: 'absolute',
            top: 50,
            left: 50,
        }
        }/>
        <MarqueeRing
            position={[0, 0, 3.5]}
            rotateParams={[0.02, -0.02, -0.02]}
            style={{
            position: 'absolute',
            top: 300,
            right: 100,
        }
        }/>
        <MarqueeRing
            position={[0, 0, 4.3]}
            rotateParams={[0.02, 0.01, 0.01]}
            style={{
            position: 'absolute',
            top: 0,
            right: 50,
        }
        }/>
        <MarqueeRing
            position={[0, 0, 3.0]}
            rotateParams={[-0.01, -0.02, 0.01]}
            style={{
            position: 'absolute',
            top: 300,
            left: screenWidth / 3,
        }
        }/>
    </div>
}

export default Marquee;