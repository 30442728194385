import * as React from "react"
import {motion, AnimatePresence, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useIsMobile} from "../utils";
import {GoIcon} from "./icon";
import {useEffect} from "react";


// @ts-ignore
const ProductItem = (props) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const {screenWidth} = useIsMobile();
    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 2,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            y: 70,
        },
    };
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    return (<motion.div
        style={{
            ...props.style,
        }}
        variants={variants}
        initial="hidden"
        animate={controls}
        ref={ref}
    >
        <div style={{
            width: '100%',
            position: 'relative',
            ...(screenWidth < 1000 ? {
                maxHeight: 500,
            } : {}),
        }}>
            <img src={props.src} style={{
                width: '100%',
                objectFit: 'cover',
                ...(screenWidth < 1000 ? {
                    maxHeight: 500,
                } : {}),
            }}/>
            <div style={{
                position: 'absolute',
                bottom: 24,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}>
                <div style={{
                    position: 'relative',
                    display: 'table-cell',
                    padding: '16px 40px',
                    backgroundColor: '#F0EDE8',
                    borderRadius: 72
                }}>
                    {props.label}
                </div>
            </div>
        </div>
    </motion.div>);
}

const Collection = () => {
    const {screenWidth} = useIsMobile();
    const height = (screenWidth - 220) * 0.74;
    const productMobileStyle = {
        width: '100%',
        margin: '24px 0',
        maxHeight: 500,
    }
    return (
        <div>
            <div style={{
                ...(screenWidth < 1000 ? {
                    padding: '0px 16px',
                    margin: '0px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                } : {
                    position: 'relative', margin: 84, height
                }),
            }}>
                <AnimatePresence>
                    <motion.div
                        key="segment-collection"
                        style={{
                            ...(screenWidth < 1000 ? {} : {
                                position: 'absolute',
                                top: (screenWidth - 220) * 0.24 / 2,
                                fontSize: 24,
                            }),
                        }}
                    >
                        / COLLECTION
                    </motion.div>
                    <ProductItem
                        key="item2"
                        src={'/item2.png'}
                        label="Hand Cream"
                        style={{
                            ...(
                                screenWidth < 1000 ? productMobileStyle
                                    : {
                                        position: 'absolute',
                                        top: 140,
                                        right: (screenWidth - 220) * 0.32 + 24,
                                        left: (screenWidth - 220) * 0.235 + 24,
                                    }
                            )
                        }}
                    />
                    <ProductItem
                        key="item1"
                        src={'/item1.png'}
                        label="Face Cream"
                        style={{
                            ...(
                                screenWidth < 1000 ? productMobileStyle
                                 : {
                                    position: 'absolute',
                                    top: (screenWidth - 220) * 0.24,
                                    left: 0,
                                    width: (screenWidth - 220) * 0.235,
                                }
                            )
                        }}
                    />
                    {
                        screenWidth < 1000 ? [
                                <ProductItem
                                    key="item3"
                                    src={'/item3.png'}
                                    label="Face Cream"
                                    style={productMobileStyle}
                                />,
                                <ProductItem
                                    key="item4"
                                    label="Hand Cream"
                                    src={'/item4.png'}
                                    style={productMobileStyle}
                                />
                            ] :
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                width: (screenWidth - 220) * 0.32
                            }}>
                                <ProductItem
                                    key="item3"
                                    src={'/item3.png'}
                                    label="Face Cream"
                                    style={{width: '100%', marginBottom: 24}}
                                />
                                <ProductItem
                                    key="item4"
                                    label="Hand Cream"
                                    src={'/item4.png'}
                                    style={{
                                        width: (screenWidth - 220) * 0.235
                                    }}
                                />
                            </div>
                    }
                </AnimatePresence>
                <motion.div style={{
                    fontSize: 20,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    ...(
                        screenWidth < 1000 ? {
                            justifyContent: 'right',
                            marginTop: 24,
                            }
                 : {
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                        }
                    )
                }}>
                    <GoIcon
                        arrowColor="black"
                        circleColor="rgba(19, 19, 19, 0.3)"
                    />
                    <div style={{width: 24}}></div>
                    View All
                </motion.div>
            </div>
        </div>
    );
}

export default Collection;