import * as React from "react"
import Logo from "./logo";
import {useIsMobile} from "../utils";

const Footer = () => {
    const { screenWidth } = useIsMobile();
    const isMobile = screenWidth < 875;
    return (
      <div style={{
          display: 'flex', padding: '128px 84px 48px 84px', justifyContent: 'space-between',
          ...(isMobile ? {
              flexDirection: 'column',
              alignItems: 'left',
              padding: '48px 24px',
          } : {}),
      }}>
          <div style={{
             flexGrow: 1,
          }}>
              <Logo />
          </div>
          { isMobile && <div style={{ height: 24}}></div>}
          <div style={{
              display: 'flex',
              flexGrow: 1,
          }}>
              <div style={{ cursor: 'pointer'}}>
                  Privacy Policy
              </div>
              <div style={{ color: '#131313' , opacity: 0.3, padding: '0px 8px'}}>
                  /
              </div>
              <div style={{ cursor: 'pointer'}}>
                  Brand Site
              </div>
          </div>
          { isMobile && <div style={{ height: 24}}></div>}
          © original COSME JAPAN CO.,LTD
      </div>
    );
}

export  default Footer;