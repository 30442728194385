import * as React from "react"
import {useState, useEffect, useRef} from "react";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";


const Compass = ({width, height}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const lineVariants = {
        hidden: {
            pathLength: 0
        },
        visible: {
            pathLength: 1,
            transition: {
                duration: 3,
                when: "beforeChildren",
                staggerChildren: 0.2,
                // repeat: Infinity,
                // repeatType: 'loop'
            },
        },
    }
    const lineProps = {
        initial: "hidden",
        animate: controls,
        variants: lineVariants,
    }
    const markerVariants = {
        hidden: {
            opacity: 0,
            scale: 0,
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 1,
                delay: 3,
                when: "beforeChildren",
                staggerChildren: 0.2,
                // repeat: Infinity,
                // repeatType: 'loop'
            },
        },
    }
    const markerProps = {
        initial: "hidden",
        variants: markerVariants,
        animate: controls
    }

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <svg ref={ref} width={width} height={height} viewBox="0 0 119 157" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <motion.path
                {...lineProps}
                d="M106.086 64.6715C97.7696 50.3165 86.5823 39.2249 74.8878 32.6886C71.5787 30.7549 68.1341 29.063 64.5809 27.6261C65.0442 26.229 64.9795 24.7105 64.3991 23.3579C64.9071 23.0151 65.3673 22.6064 65.7676 22.1425C68.5907 18.8695 68.5812 13.3668 65.7676 8.46695C63.2794 4.16046 59.2983 1.48086 55.3937 1.48086C54.8963 1.48142 54.3999 1.52626 53.9104 1.61484C53.0961 1.77197 52.3135 2.06302 51.5944 2.47614C47.087 5.07917 46.2544 11.7877 49.6804 17.7881C50.7278 19.65 52.1444 21.2782 53.8434 22.5731C53.4703 23.1606 53.2016 23.8081 53.0491 24.4871C49.4316 24.0469 45.9864 24.3149 43.2781 25.8748L19.1043 39.6843L18.6928 39.9905C-2.13152 52.0104 -4.42832 86.9983 13.5441 118.158C31.5166 149.318 62.9731 164.802 83.7783 152.792L92.8698 147.605L100.956 142.82C121.762 130.8 124.068 95.8123 106.086 64.6715Z"
                fill="#F2F2F2"/>
            <motion.path
                {...lineProps}
                d="M18.645 39.4449C-2.16017 51.4553 -4.46654 86.4432 13.5059 117.584C31.4784 148.725 62.9445 164.228 83.7497 152.208L92.8412 147.021"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M68.0159 146.323C52.2254 146.323 33.784 132.771 22.1278 112.598C7.18901 86.7589 7.86848 57.1589 23.6207 48.0674C25.5724 46.9402 27.7019 46.1538 29.9177 45.7419C31.4002 45.4597 32.9066 45.3219 34.4156 45.3304C50.2157 45.3304 68.6571 58.8815 80.2942 79.0455C93.1658 101.411 94.7066 126.771 83.9691 139.212C82.4984 140.947 80.7407 142.417 78.7726 143.557C75.4969 145.419 71.7836 146.374 68.0159 146.323V146.323Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M68.0159 146.323C52.2254 146.323 33.784 132.771 22.1278 112.598C7.18901 86.7589 7.86848 57.1589 23.6207 48.0674C25.5724 46.9402 27.7019 46.1538 29.9177 45.7419C31.4002 45.4597 32.9066 45.3219 34.4156 45.3304C50.2157 45.3304 68.6571 58.8815 80.2942 79.0455C93.1658 101.411 94.7066 126.771 83.9691 139.212C82.4984 140.947 80.7407 142.417 78.7726 143.557C75.4969 145.419 71.7836 146.374 68.0159 146.323V146.323Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M58.8582 31.1766C62.1721 31.1766 64.8586 28.4901 64.8586 25.1762C64.8586 21.8623 62.1721 19.1758 58.8582 19.1758C55.5443 19.1758 52.8578 21.8623 52.8578 25.1762C52.8578 28.4901 55.5443 31.1766 58.8582 31.1766Z"
                fill="#F2F2F2" stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M60.0163 24.1805C56.1118 24.1805 52.1402 21.5104 49.652 17.1944C46.2068 11.2323 47.0394 4.52371 51.566 1.88239C52.2892 1.47082 53.0747 1.1799 53.8915 1.02109C54.381 0.932507 54.8774 0.88767 55.3749 0.887108C59.2794 0.887108 63.251 3.56671 65.7392 7.8732C68.6102 12.7826 68.6102 18.2758 65.7392 21.5487C65.1974 22.1871 64.55 22.7277 63.8252 23.1469C62.6677 23.8184 61.3545 24.1747 60.0163 24.1805Z"
                fill="#F2F2F2" stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M59.0788 16.7162C57.7677 16.7162 56.2078 15.5965 55.2508 13.9409C54.0258 11.8067 54.0832 9.3664 55.3752 8.61994C55.5358 8.5282 55.7104 8.46354 55.892 8.42854C56.0162 8.41912 56.141 8.41912 56.2652 8.42854C57.5667 8.42854 59.1362 9.54823 60.0932 11.2134C61.1555 13.0508 61.2799 15.1371 60.3994 16.1707C60.2789 16.3142 60.1329 16.4343 59.9688 16.5248C59.695 16.6681 59.3873 16.7343 59.0788 16.7162Z"
                fill="#F2F2F2" stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M19.0469 39.1384L43.2398 25.2332" stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M100.928 142.255C121.733 130.245 124.03 95.2571 106.057 64.1259C97.7411 49.723 86.5442 38.6697 74.8593 32.1334"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M100.142 135.011C107.32 118.627 104.813 94.453 91.9888 72.2411C77.4042 46.9763 53.9481 32.0279 34.5114 33.674C30.0195 34.0265 25.6621 35.3732 21.7546 37.6168"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>

            <motion.path
                {...markerProps}
                d="M55.6621 109.354C55.4876 109.568 55.2848 109.758 55.0592 109.919C54.9704 109.985 54.8777 110.045 54.7817 110.1C54.2204 110.414 53.5871 110.576 52.9443 110.569C50.2359 110.569 47.0778 108.253 45.0873 104.827C42.5321 100.396 42.6469 95.3434 45.3457 93.7835C45.678 93.5907 46.0403 93.4549 46.4175 93.3816C46.6699 93.3343 46.9263 93.3119 47.1831 93.3146C49.8914 93.3146 53.04 95.6401 55.0401 99.0566C57.2507 102.846 57.5091 107.22 55.6621 109.354Z"
                fill="#F2F2F2" stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M52.8577 60.4416V67.2459C52.8642 67.2889 52.8543 67.3327 52.8298 67.3687C52.8053 67.4047 52.7682 67.43 52.7258 67.4397C52.6834 67.4494 52.6389 67.4427 52.6012 67.421C52.5635 67.3992 52.5355 67.364 52.5227 67.3224L47.8143 57.2452C47.8002 57.2135 47.7768 57.1869 47.7473 57.1687L45.1251 55.647C45.0986 55.6317 45.0686 55.6238 45.038 55.6239C45.0074 55.6241 44.9774 55.6324 44.9511 55.648C44.9248 55.6636 44.9031 55.6859 44.8883 55.7126C44.8735 55.7394 44.866 55.7696 44.8667 55.8002V67.9732C44.8649 68.0043 44.8721 68.0352 44.8874 68.0624C44.9026 68.0895 44.9254 68.1117 44.9529 68.1263L47.5368 69.5714C47.5632 69.5867 47.5933 69.5947 47.6239 69.5945C47.6545 69.5943 47.6844 69.586 47.7108 69.5704C47.7371 69.5548 47.7587 69.5325 47.7736 69.5058C47.7884 69.479 47.7959 69.4488 47.7951 69.4183V62.5374C47.7886 62.4944 47.7986 62.4506 47.823 62.4146C47.8475 62.3786 47.8847 62.3533 47.9271 62.3436C47.9695 62.3339 48.014 62.3406 48.0516 62.3623C48.0893 62.3841 48.1173 62.4193 48.1301 62.4609L52.829 72.4998C52.8435 72.5343 52.871 72.5618 52.9055 72.5764L55.4703 74.0023C55.4968 74.0176 55.5268 74.0256 55.5574 74.0254C55.588 74.0252 55.618 74.0169 55.6443 74.0013C55.6706 73.9857 55.6923 73.9634 55.7071 73.9367C55.7219 73.9099 55.7294 73.8797 55.7287 73.8492V61.8867C55.7269 61.8576 55.7181 61.8294 55.7031 61.8044C55.6881 61.7794 55.6673 61.7584 55.6425 61.7431L53.1352 60.2885C53.1086 60.2677 53.0765 60.2551 53.0428 60.2524C53.0091 60.2497 52.9754 60.257 52.9458 60.2733C52.9163 60.2896 52.8921 60.3143 52.8765 60.3442C52.8608 60.3741 52.8543 60.408 52.8577 60.4416Z"
                fill="#D68F4C"/>
            <motion.path
                {...markerProps}
                d="M45.4984 105.621L50.1111 125.249L55.078 109.899" fill="#D68F4C"/>
            <motion.path
                {...markerProps}
                d="M45.4984 105.621L50.1111 125.249L55.078 109.899" stroke="#D68F4C" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M55.5471 99.736L50.0827 76.9116L44.0344 95.0084" fill="#F2F2F2"/>
            <motion.path
                {...markerProps}
                d="M55.5471 99.736L50.0827 76.9116L44.0344 95.0084" stroke="#D68F4C" stroke-linecap="round"
                stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M20.5781 80.0791L30.885 86.0221" stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M70.2078 108.579L80.5051 114.531" stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
}

const Globe = ({width, height}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const lineVariants = {
        hidden: {
            pathLength: 0
        },
        visible: {
            pathLength: 1,
            transition: {
                duration: 3,
                when: "beforeChildren",
                staggerChildren: 0.2,
                // repeat: Infinity,
                // repeatType: 'loop'
            },
        },
    }
    const lineProps = {
        initial: "hidden",
        animate: controls,
        variants: lineVariants,
    }
    const markerVariants = {
        hidden: {
            opacity: 0,
            scale: 0,
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 1,
                delay: 3,
                when: "beforeChildren",
                staggerChildren: 0.2,
                // repeat: Infinity,
                // repeatType: 'loop'
            },
        },
    }
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    const markerProps = {
        initial: "hidden",
        variants: markerVariants,
        animate: controls,
    }
    return (
        <svg ref={ref} width={width} height={height} viewBox="0 0 140 165" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            {/*Line*/}
            <motion.path
                {...lineProps}
                d="M69.4892 163.034C107.56 163.034 138.423 132.39 138.423 94.5894C138.423 56.7885 107.56 26.1448 69.4892 26.1448C31.4182 26.1448 0.555664 56.7885 0.555664 94.5894C0.555664 132.39 31.4182 163.034 69.4892 163.034Z"
                fill="#F2F2F2"/>
            <motion.path
                {...lineProps}
                d="M53.0277 43.1917C44.0115 45.438 35.5405 49.4786 28.1208 55.0721C20.7012 60.6657 14.4851 67.6973 9.84393 75.7471"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M33.4661 73.0564C22.7228 67.4549 16.2309 59.7565 16.2309 52.5273" stroke="#D68F4C"
                stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M113.466 68.393C103.373 76.2446 87.1053 81.2332 69.7648 81.2428" stroke="#D68F4C"
                stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M39.8052 32.4101C49.1163 27.9436 59.3143 25.633 69.6413 25.6501C73.7212 25.6481 77.7935 26.0038 81.8113 26.7129"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M6.90491 65.9133C13.6382 51.2513 25.2773 39.3988 39.8146 32.4004" stroke="#D68F4C"
                stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M81.8107 26.7128C95.1282 29.0901 107.45 35.3378 117.239 44.6757" stroke="#D68F4C"
                stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M38.5601 33.0422C52.34 26.1098 68.0392 23.9734 83.1705 26.9716" stroke="#D68F4C"
                stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M116.521 43.996C128.037 54.6252 135.58 68.8665 137.902 84.3647C138.41 87.7755 138.666 91.2191 138.668 94.6675C138.68 105.035 136.356 115.272 131.867 124.617C127.379 133.963 120.842 142.177 112.743 148.649C104.644 155.121 95.1901 159.684 85.0847 162C74.9792 164.316 64.4818 164.326 54.3724 162.027C44.2629 159.728 34.8015 155.181 26.691 148.723C18.5806 142.265 12.0297 134.063 7.5251 124.725C3.02054 115.387 0.678133 105.155 0.671948 94.7871C0.665763 84.4197 2.99596 74.1841 7.48937 64.841"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M69.669 52.6997C58.763 52.6997 50.605 47.3472 50.605 42.5597C50.6094 42.044 50.7 41.5326 50.8731 41.0468C50.9985 40.6842 51.1588 40.3347 51.3518 40.0031C53.9371 35.5315 61.4823 32.41 69.669 32.41C77.4249 32.41 86.5787 35.6369 88.465 41.0277C88.6404 41.5162 88.7311 42.0311 88.7331 42.5501C88.7195 43.4528 88.465 44.3354 87.9958 45.1066C85.4105 49.5687 77.8749 52.6901 69.669 52.6997Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M137.911 83.6561C137.849 86.8914 136.916 90.0501 135.211 92.8003C125.981 108.8 99.0078 119.974 69.6217 119.984C30.5744 119.984 1.38947 100.834 1.38947 83.6848"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M131.506 125.289C119.939 138.454 95.7523 147.311 69.6601 147.311C41.5954 147.311 18.6247 137.411 7.92932 125.48"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M73.385 81.1668C72.1498 81.1668 70.9146 81.2434 69.6603 81.2434C54.0337 81.2434 40.4179 77.3367 30.8428 71.7257"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M123.358 52.6615C123.319 55.2053 122.599 57.6922 121.27 59.862C120.196 61.6769 118.911 63.3579 117.44 64.8698C115.758 66.6179 113.907 68.1959 111.915 69.5807"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M30.1532 146.249C29.5149 144.002 28.9595 141.72 28.4871 139.402C27.9126 136.626 27.4658 133.804 27.1466 130.938C26.7455 127.409 26.5473 123.861 26.553 120.31C26.553 117.322 26.687 114.367 26.9551 111.443C28.1223 98.7906 31.8455 86.5066 37.8995 75.3353V75.3353C43.1121 65.7003 49.9541 57.0409 58.1221 49.741C61.6215 46.5916 65.3536 43.7109 69.2867 41.1234L69.4495 41.0181C75.0236 37.3698 80.9676 34.3208 87.1826 31.9217"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M84.9318 53.9062C83.5147 52.4699 82.056 51.0815 80.5559 49.741C77.0514 46.5937 73.3163 43.7132 69.3818 41.1234L69.2286 41.0181C63.6496 37.3723 57.7027 34.3234 51.4859 31.9217"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M107.09 89.7549C105.376 84.7808 103.253 79.9575 100.741 75.3348C96.5083 67.4823 91.1861 60.2678 84.9327 53.9057"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M111.714 111.443C111.028 104.057 109.476 96.7782 107.089 89.7553" stroke="#D68F4C"
                stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M85.0092 53.8296C83.6303 52.438 82.2132 51.0911 80.7578 49.7889C77.3151 46.7022 73.6472 43.8761 69.7847 41.3341L69.6219 41.2767C66.8796 39.4788 64.0447 37.8264 61.1288 36.3263C57.7446 34.5724 54.2559 33.0279 50.6824 31.7015"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M108.64 146.23C109.282 143.97 109.847 141.681 110.316 139.364C110.89 136.581 111.337 133.756 111.656 130.89C112.058 127.362 112.256 123.813 112.25 120.262C112.25 117.274 112.113 114.319 111.838 111.395C111.535 108.107 111.059 104.838 110.412 101.6M107.214 89.7172C108.536 93.6045 109.605 97.5738 110.412 101.6L107.214 89.7172Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M1.1601 102.385C2.28996 92.1145 5.7269 82.2325 11.214 73.4776" stroke="#D68F4C"
                stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M42.6102 46.648C51.1148 43.0246 60.2623 41.1521 69.5067 41.1423H69.6694C86.6365 41.1292 103.013 47.3734 115.664 58.6799C128.314 69.9864 136.352 85.561 138.237 102.423"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M69.6702 29.4802V81.2432V159.108" stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...lineProps}
                d="M115.851 8.87425C112.902 3.75156 108.191 0.582199 103.556 0.582199C102.969 0.582556 102.382 0.637045 101.804 0.744976C100.836 0.925856 99.9063 1.27245 99.056 1.76951L92.8705 5.32188C87.8149 8.24229 86.7233 15.596 90.2086 22.3464L101.048 45.8246H101.871L107.329 42.8372L117.067 23.3039C117.949 21.5667 118.396 19.6411 118.369 17.6929C118.316 14.5837 117.447 11.5429 115.851 8.87425V8.87425Z"
                fill="#F2F2F2"/>
            {/*Marker*/}
            <motion.path
                {...markerProps}
                d="M100.74 22.6149C99.218 22.6149 97.4466 21.3126 96.3168 19.3689C94.8805 16.8698 94.9475 14.026 96.4604 13.1546C96.6485 13.0404 96.8564 12.9625 97.0732 12.9248C97.2164 12.9098 97.3609 12.9098 97.5041 12.9248C99.0265 12.9248 100.798 14.2271 101.918 16.1708C103.163 18.3252 103.316 20.7669 102.282 21.9733C102.141 22.1412 101.973 22.2837 101.784 22.3946C101.461 22.5564 101.102 22.6322 100.74 22.6149Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M103.614 38.7965L105.242 35.5314L109.694 26.6169C110.564 24.9035 111.005 23.0041 110.977 21.0825C110.922 18.0119 110.067 15.0087 108.497 12.3692C107.392 10.4279 105.945 8.70217 104.227 7.27521"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M104.897 0.668376C104.452 0.610763 104.005 0.581978 103.556 0.5822C102.969 0.582557 102.382 0.637046 101.804 0.744977C100.836 0.925858 99.9063 1.27246 99.056 1.76951L92.8705 5.32188C87.8149 8.24229 86.7233 15.596 90.2086 22.3464L98.8262 41.1137C99.1121 41.729 99.517 42.2818 100.018 42.7399C100.518 43.1981 101.104 43.5527 101.743 43.7832C102.381 44.0138 103.058 44.1157 103.736 44.0831C104.414 44.0506 105.078 43.8842 105.692 43.5936V43.5936C106.732 43.1053 107.576 42.2782 108.085 41.2477L117.029 23.3039C117.911 21.5667 118.358 19.6411 118.331 17.6929C118.277 14.5837 117.409 11.5429 115.812 8.87425C113.189 4.24947 109.081 1.21416 104.897 0.668376Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M33.3223 37.0923C30.3732 31.9792 25.6622 28.8002 21.0279 28.8002C20.4364 28.7997 19.8462 28.8574 19.266 28.9726C18.2861 29.1492 17.3437 29.4925 16.4797 29.9875L10.2942 33.5399C5.27681 36.489 4.18524 43.814 7.67058 50.5644L18.5096 74.0426H19.3522L24.8196 71.0552L34.5383 51.5315C35.4184 49.7935 35.8651 47.8685 35.8406 45.9205C35.7875 42.8082 34.9192 39.7642 33.3223 37.0923V37.0923Z"
                fill="#F2F2F2"/>
            <motion.path
                {...markerProps}
                d="M18.1839 50.8515C16.6615 50.8515 14.8805 49.5397 13.7602 47.5959C12.3239 45.1064 12.391 42.253 13.9038 41.3817C14.0958 41.2756 14.3022 41.1982 14.5166 41.1519C14.6569 41.142 14.7977 41.142 14.9379 41.1519C16.4604 41.1519 18.2413 42.4637 19.3616 44.4074C20.6064 46.5618 20.7596 49.0035 19.7255 50.2004C19.582 50.3691 19.4103 50.5116 19.218 50.6217C18.9018 50.794 18.5434 50.8736 18.1839 50.8515Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M21.0562 67.0146L22.7127 63.7016L27.1556 54.7872C28.0259 53.0738 28.4663 51.1743 28.4386 49.2528C28.3834 46.1821 27.5286 43.1789 25.9587 40.5394C24.8549 38.6 23.4116 36.8746 21.6977 35.4454"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M22.3684 28.896C21.3374 28.7463 20.2884 28.7722 19.266 28.9726C18.2861 29.1493 17.3437 29.4925 16.4797 29.9876L10.2942 33.54C5.27681 36.4891 4.18524 43.8141 7.67058 50.5645L16.3361 69.3317C16.6212 69.9456 17.0246 70.4971 17.5233 70.9546C18.0221 71.4121 18.6062 71.7667 19.2422 71.998C19.8783 72.2293 20.5538 72.3328 21.2299 72.3024C21.906 72.2721 22.5695 72.1086 23.1823 71.8213V71.8213C24.2169 71.3244 25.0584 70.4997 25.576 69.4754L34.5192 51.5316C35.3992 49.7936 35.846 47.8685 35.8214 45.9206C35.7684 42.8083 34.9001 39.7643 33.3032 37.0923C30.6604 32.4771 26.5527 29.4322 22.3684 28.896Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M104.571 66.4212C101.612 61.3081 96.9105 58.1387 92.2666 58.1387C91.6788 58.1391 91.0922 58.1936 90.5144 58.3015C89.548 58.4835 88.619 58.8266 87.7663 59.3165L81.5904 62.8784C76.5347 65.7509 75.4431 73.1525 78.9285 79.903L89.7675 103.381H90.591L96.0488 100.394L105.787 80.8605C106.669 79.1232 107.116 77.1977 107.089 75.2495C107.031 72.1379 106.163 69.0953 104.571 66.4212Z"
                fill="#F2F2F2"/>
            <motion.path
                {...markerProps}
                d="M89.4235 80.1711C87.9011 80.1711 86.1297 78.8689 85.0094 76.9252C83.5635 74.426 83.6306 71.5822 85.153 70.7013C85.3394 70.5927 85.5437 70.5182 85.7562 70.4811C85.8995 70.466 86.0439 70.466 86.1871 70.4811C87.7096 70.4811 89.4809 71.7833 90.6108 73.7271C91.846 75.8815 91.9992 78.3231 90.9651 79.5296C90.777 79.7529 90.5403 79.9303 90.2731 80.0481C90.0059 80.166 89.7153 80.2211 89.4235 80.2094V80.1711Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M92.3053 96.353L93.9522 93.0401L98.4046 84.1256C99.2704 82.4209 99.7107 80.5318 99.6877 78.6199C99.6325 75.5493 98.7777 72.5461 97.2078 69.9066C96.1026 67.9653 94.6559 66.2396 92.9373 64.8126"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
            <motion.path
                {...markerProps}
                d="M93.6167 58.2249C93.169 58.1676 92.718 58.1388 92.2666 58.1387C91.6788 58.1391 91.0922 58.1936 90.5144 58.3015C89.548 58.4835 88.619 58.8266 87.7663 59.3165L81.5904 62.8784C76.5347 65.7509 75.4431 73.1525 78.9285 79.903L87.594 98.6702C87.8789 99.2852 88.2828 99.8377 88.7825 100.296C89.2821 100.754 89.8676 101.108 90.505 101.339C91.1424 101.57 91.8192 101.672 92.4963 101.639C93.1733 101.607 93.8373 101.441 94.4497 101.15V101.15C95.4899 100.661 96.3337 99.8343 96.8435 98.8042L105.787 80.8605C106.669 79.1232 107.116 77.1977 107.089 75.2495C107.031 72.1379 106.163 69.0953 104.571 66.4212C101.899 61.806 97.7915 58.7707 93.6167 58.2249Z"
                stroke="#D68F4C" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
}

const Description = (props) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            y: 70,
        },
    };
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    return (
        <motion.div ref={ref}
                    variants={variants}
                    initial="hidden"
                    animate={controls}
        >
            <div style={{fontSize: 24, fontWeight: 'bold', marginBottom: 16}}>
                {props.title}
            </div>
            <div style={{color: '#505050'}}>
                {props.content}
            </div>
        </motion.div>
    );
}

export const Line = () =>
    <hr
        style={{
            height: 1,
            opacity: 0.1,
            color: '#131313',
            background: '#131313',
            width: '100%',
        }}
    />
const Service = () => {
    return <div
        style={{
            overflow: 'hidden',
            height: 700,
        }}
    >
        <div style={{
            fontSize: 24,
            margin: '56px 84px',
        }}>
            / SERVICE
        </div>
        <Line/>
        <div style={{
            overflowX: 'auto',
        }}>
            <div style={{
                display: 'flex',
                minWidth: 650,
            }}>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', padding: 40}}>
                    <div style={{
                        margin: '24px 0 64px 52px',
                    }}>
                        <Globe
                            width={176}
                            height={176}
                        />
                    </div>
                    <Description
                        title={(
                            <div>
                                Multinational<br/> Service Base
                            </div>
                        )}
                        content="Do et reprehenderit exercit reprehendetation excepteur aliqua duis culpa"
                    />
                </div>
                <div style={{
                    flexGrow: 1, display: 'flex', flexDirection: 'column', padding: 40,
                    borderLeft: '1px solid rgba(19,19,19,0.1)',
                }}>
                    <Description
                        title={(
                            <div>
                                Multinational<br/> Service Base
                            </div>
                        )}
                        content="Do et reprehenderit exercit reprehendetation excepteur aliqua duis culpa"
                    />
                    <div style={{
                        margin: '118px 0 64px 52px',
                    }}>
                        <Compass
                            width={176}
                            height={176}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Service;