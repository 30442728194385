import * as THREE from 'three'
import ReactDOM from 'react-dom'
import React, {useRef, useState} from 'react'
import {Canvas, useFrame} from '@react-three/fiber'
import {useGLTF} from "@react-three/drei";

export function Box(props: JSX.IntrinsicElements['mesh']) {
    const ref = useRef<THREE.Mesh>(null!)
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
    useFrame((state, delta) => (ref.current.rotation.x += 0.01))
    return (
        <mesh
            {...props}
            ref={ref}
            scale={clicked ? 1.5 : 1}
            onClick={(event) => click(!clicked)}
            onPointerOver={(event) => hover(true)}
            onPointerOut={(event) => hover(false)}>
            <boxGeometry args={[1, 1, 1]}/>
            <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'}/>
        </mesh>
    )
}

function Watch(props: JSX.IntrinsicElements['mesh']) {
    const ref = useRef()
    const {nodes, materials} = useGLTF('/watch-v1.glb')
    console.log(materials);
    console.log(nodes);
    // const [hovered, hover] = useState(false)
    // const [clicked, click] = useState(false)
    // useFrame((state, delta) => (ref.current.rotation.x += 0.01))
    return (
        <mesh
            {...props}
            castShadow
            // onClick={(event) => click(!clicked)}
            // onPointerOver={(event) => hover(true)}
            // onPointerOut={(event) => hover(false)}
            geometry={nodes.Object006_watch_0.geometry}
            material={materials['watch']}
        />
    )
}

function _Ring(props: JSX.IntrinsicElements['mesh']) {
    const ref = useRef()
    const {nodes, materials} = useGLTF('/ring.glb')
    const [hovered, hover] = useState(false)
    const rotateParams = props.rotateParams || [0.01, 0.01, 0.01];

    useFrame((state, delta) => {
        ref.current.rotation.x += rotateParams[0];
        ref.current.rotation.y += rotateParams[1];
        ref.current.rotation.z += rotateParams[2];
        return;
    })
    return (
        <group ref={ref} {...props} dispose={null}>
            <mesh
                {...props}
                scale={10}
                castShadow
                // onClick={(event) => click(!clicked)}
                onPointerOver={(event) => hover(true)}
                onPointerOut={(event) => hover(false)}
                geometry={nodes.Torus.geometry}
                material={materials['Matte 2']}
            >
                <meshStandardMaterial color={hovered ? 'orange' : 'white'} />
            </mesh>
            {/*<Watch />*/}
            {/*<Box />*/}
        </group>
    )
}

const Ring = (props) => {
    return (
        <div style={{...props.style }}>
            <Canvas shadows dpr={[1, 2]} camera={{position: props.position || [0, 0, 4], fov: 50}}>

                <ambientLight intensity={0.5}/>
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={[512, 512]} castShadow/>
                <React.Suspense fallback={null}>
                    <_Ring rotateParams={props.rotateParams}/>
                </React.Suspense>
            </Canvas>
        </div>
    );
}

export default Ring;