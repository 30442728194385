import * as React from "react"
import {motion, AnimatePresence} from "framer-motion";
import {useIsMobile} from "../utils";
import Concept from "../components/concept";
import Collection from "../components/collection";
import Service, {Line} from "../components/service";
import '../index.css';
import Marquee from "../components/marquee";
import Contact from "../components/contact";
import Footer from "../components/footer";


// markup
const IndexPage = () => {
    const {screenWidth, screenHeight} = useIsMobile();
    return (
        <main>
            <title>Original</title>
            {
                typeof window === 'undefined' ? (<></>) :[
                    <Concept />,
                    <div style={{ height: 152, width: 152}} />,
                    <Collection />,
                    <div style={{ height: 48, width: 152}} />,
                    <Service />,
                    <Line/>,
                    <div style={{ height: 48, width: 152}} />,
                    <Marquee />,
                    <div style={{ height: 96, width: 152}} />,
                    <Contact />,
                    <Footer />,
                ]
            }
        </main>
    )
}

export default IndexPage
