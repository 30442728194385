import { useState, useEffect, useRef } from "react";

export const useIsMobile = () => {
    const [width, setWidth] = useState<number>(
        typeof  window !== "undefined" ? window.innerWidth : 1920
    );

    const [height, setHeight] = useState<number>(
        typeof  window !== "undefined" ? window.innerHeight: 1025
    );

    function handleWindowSizeChange() {
        setWidth(
            typeof  window !== "undefined" ? window.innerWidth : 1920
        );
        setHeight(
            typeof  window !== "undefined" ? window.innerHeight : 1025
        );
    }
    useEffect(() => {
        if (typeof  window == "undefined") {
            return;
        }
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return  { isMobile : width <= 768, screenWidth: width, screenHeight: height };
}

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // 保存新回调
    useEffect(() => {
        savedCallback.current = callback;
    });

    // 建立 interval
    useEffect(() => {
        function tick() {
            // @ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}