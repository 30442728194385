import * as React from "react"
import {motion, AnimatePresence, useAnimation, animate} from "framer-motion";
import {useIsMobile} from "../utils";
import Mug from "./mug";
import {useInView} from "react-intersection-observer";
import {useEffect, useState} from "react";

const Container = () => {

    const [ref, inView] = useInView();
    const controls = useAnimation();
        const variants = {
        initial: {
            rotate: 0,
            scale: 0,
        },
        active: {
            scale: 1,
            rotate: 720,
            transition: {
                duration: 3,
            }
        },
    }
    useEffect(() => {
        if (inView) {
            controls.start('active');
        }
    }, [inView]);
    return (
        <div style={{ position: 'relative'}}>
            <motion.img
                ref={ref}
                variants={variants}
                src="/container.png"
                animate={controls}
                initial="initial"
                style={{ maxHeight: 480}}
            >
            </motion.img>
            <motion.div
                variants={{
                    initial: {
                        opacity: 0
                    },
                    active: {
                        opacity: 1,
                        transition: {
                            delay: 3,
                        }
                    }
                }}
                initial={"initial"}
                animate={controls}
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <div style={{
                    width: 80,
                    height: 80,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#dfad77',
                    borderRadius: 100,
                    cursor: 'pointer',
                }}>
                    <embed src="./mail.svg" style={{
                        margin: '0px auto',
                        cursor: 'pointer',
                    }}/>
                </div>
            </motion.div>
        </div>
    );
}

const Contact = () => {
    const { screenHeight, screenWidth } = useIsMobile();
    const [ref, inView] = useInView();
    const [showMug, setShowMug] = useState(false);
    useEffect(() => {
        if (inView) {
            setShowMug(true);
        }
    }, [inView]);

    const isMobile = screenWidth < 850;

    return <div style={{
        height: screenHeight, width: '100%',
        justifyContent: isMobile ? 'center' : 'space-between',
        alignItems: 'center',  display: 'flex', backgroundColor: '#F2EEE6', opacity: 0.88,
        flexDirection: screenWidth < 850 ? 'column' : 'row',
            ...(isMobile ? { padding : 24} : {})
    }}

    >
        <div style={{
            fontSize: 24,
            display: 'flex',
            alignItems: 'center',
            color: '#dfad77',
            ...(isMobile ? {
                width: '100%',
            } : {
                margin: '0px 84px',
            })
        }}>
            / CONTACT
        </div>
        <Container />
        {screenWidth > 850 && <div></div>}
        {/*<div*/}
        {/*    ref={ref}*/}
        {/*    style={{*/}
        {/*        position: 'absolute',*/}
        {/*        left: 0,*/}
        {/*        right: 0,*/}
        {/*        top: 0,*/}
        {/*        bottom: 0,*/}
        {/*        display: 'flex',*/}
        {/*        alignItems: 'center',*/}
        {/*        justifyContent: 'center',*/}
        {/*    }}*/}
        {/*>*/}
        {/*    {*/}
        {/*        showMug &&*/}
        {/*        <Mug />*/}
        {/*    }*/}
        {/*</div>*/}
</div>
}

export default Contact;