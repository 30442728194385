import * as React from "react"
import {motion, AnimatePresence, useAnimation} from "framer-motion";
import {useInterval, useIsMobile} from "../utils";
import Logo from "./logo";
import Ring from "./ring";
import Header from "./header";
import {GoIcon} from "./icon";
import {useInView} from "react-intersection-observer";
import {useEffect, useState} from "react";

const Description = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 2,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            y: 70,
        },
    };
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    return (
        <motion.div
            key="description"
            animate={controls}
            initial="hidden"
            variants={variants}
            style={{
                margin: 48,
                marginBottom: 24,
                fontSize: 14,
                position: 'absolute',
                bottom: 0,
                maxWidth: 400,
                right: 24,
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            Sit elit incididunt Lorem minim ea pariatur anim dolor ut veniam amet ad. Ipsum tempor commodo
            ullamco Lorem incididunt proident nisi nostrud dolore. Et labore minim laboris esse amet eu eu
            exercitation ea esse velit tempor culpa.
            <div style={{
                margin: '48px 0',
                fontSize: 20,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
            }}>
                <GoIcon/>
                <div
                    ref={ref}
                    style={{width: 24}}></div>
                View All
            </div>
        </motion.div>
    );
}

const Carousel = (props) => {
    const [init, setInit] = useState(false);
    const [index, setIndex] = useState(0);
    const controls = useAnimation();
    const variants = {
        initial: {
            opacity: 0,
            x: 30
        },
        active: {
            opacity: 1,
            x: 0,
        },
        exit: {
            opacity: 0,
            x: -30,
        }
    }
    const nextImage = () => {
        setInit(true);
        controls.start('exit').then(() => {
            const newIndex = index + 1;
            if (newIndex === props.images.length) {
                setIndex(0);
            } else {
                setIndex(newIndex);
            }
            setTimeout(() => {
                controls.start('active');
            }, 200);
        });
    }
    useInterval(() => {
        nextImage();
    }, 7000);
    return (
        <motion.img
            variants={variants}
            {...(init ? { initial: "initial" } : {})}
            animate={controls
            }
            key={`${props.key}-${index}`}
            src={props.images[index]}
            style={{width: props.width, height: props.height, objectFit: 'cover'}}
        />
    );
}

const Concept = () => {
    const {screenWidth, screenHeight} = useIsMobile();
    const isMobile = screenWidth < 1000;
    const mainImageWidth = screenWidth < 850 ? screenWidth*0.84 : screenWidth/2;
    const mainImageHeight =
        mainImageWidth/500*683;
    return (
        <div style={{
            minHeight:
            isMobile ? (
                mainImageHeight + 484
                ) :

                screenHeight + 64,
            backgroundColor: '#DFAD77', color: 'white', position: 'relative'}}>
            <AnimatePresence>
                <div style={{
                    padding:
                        "32px 47.74px",
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Logo color='blue'/>
                </div>
                <motion.div
                    key="sub-mage"
                    style={{position: 'absolute', top: 0, right: 0, }}
                    animate={{y: 0, opacity: 1}}
                    transition={{duration: 2}}
                    initial={{y: 70, opacity: 0}}
                >
                    <Carousel
                        key="sub-image-carousel"
                        width={
                        screenWidth < 850 ? screenWidth *0.43  :
                        screenWidth*0.3}
                        height={
                        screenWidth < 850 ? screenWidth *0.43 :
                        screenWidth*0.3/402*246
                    }
                        images={[
                            'cotton.png',
                            'pur.png',
                        ]}
                    />
                </motion.div>
                <motion.div
                    key="main-image"
                    animate={{y: 70, opacity: 1}}
                    transition={{duration: 2}}
                    initial={{x: 0, y: 0, opacity: 0}}
                >
                    <Carousel
                        key="main-image-carousel"
                        width={mainImageWidth}
                        height={mainImageHeight}
                        images={[
                            'asure.png',
                            'ordinary.png',
                            'cream.jpeg'
                        ]}
                    />
                </motion.div>
                <motion.div
                    animate={{
                        x: screenWidth < 850 ? -48 : 0,
                        y: 0, opacity: 1}}
                    transition={{duration: 2}}
                    initial={{
                         y: 70, opacity: 0}}
                    style={{
                        position: 'absolute',
                        top: 108,
                        right: 0,
                        left: 0,
                    }}
                >
                    <Ring
                        position={[0, 0, screenWidth < 850 ? 4 : 3]}
                        rotateParams={[0.01, -0.01, -0.01]}
                    />
                </motion.div>
                <motion.div
                    key="segment"
                    transition={{duration: 2}}
                    initial={{
                        opacity: 0,
                        y: 20,
                    }}
                    animate={{
                        opacity: 1,
                        y: 0,
                    }}
                    style={{
                        position: 'absolute',
                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed',
                        fontSize:
                        screenWidth < 850 ? 14 :
                            24,
                        height: screenHeight, justifyContent: 'center', alignItems: 'center',
                        top: screenHeight / 2 + (screenWidth < 850 ? 48 : 64),
                        ...(
                            screenWidth < 850 ? { left: 48 } : {
                                left: mainImageWidth + 16,
                            }
                        )
                    }}
                >
                    / CONCEPT
                </motion.div>
                <motion.div
                    key="title"
                    style={{
                        position: 'absolute',
                        top: screenHeight / 2 - (screenWidth < 850 ? 0 : 48),
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize:
                        screenWidth < 850 ? '56px' : '96px',
                        lineHeight:
                        screenWidth < 850 ? '48px' : '83px',
                        margin: 24,
                        ...(

                            screenWidth < 850 ? { right: 48 } : {
                                left: mainImageWidth + 48,
                            }
                        )
                    }}
                    animate={{
                        opacity: 1,
                        y: 0
                    }}
                    transition={{duration: 2}}
                    initial={{
                        opacity: 0,
                        y: 20,
                    }}
                >
                    Natural<br/>Skin<br/>Product
                </motion.div>
                <Description />
                <Header style={{position: 'absolute', top: 32, right: 48}}/>
            </AnimatePresence>
        </div>
    );
}

export default Concept;