import * as React from "react"
import {motion, AnimatePresence, useAnimation} from "framer-motion";
import {useIsMobile} from "../utils";

const ShopIcon = () => (
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.67385 6.55091V3.91364C3.67385 2.45655 4.7587 1.27637 6.09135 1.27637C7.42702 1.27637 8.50885 2.45984 8.50885 3.91364V6.55091M1.25635 5.23228H10.9263V15.7814H1.25635V5.23228Z" stroke="#131313" stroke-linecap="round"/>
    </svg>
);

const MenuIcon = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.0876465" y1="0.5" x2="16.0876" y2="0.5" stroke="#DFAD77"/>
        <line x1="0.0876465" y1="6.5" x2="16.0876" y2="6.5" stroke="#DFAD77"/>
        <line x1="6.08765" y1="12.5" x2="16.0876" y2="12.5" stroke="#DFAD77"/>
    </svg>
);

const Header = (props) => {
    const { isMobile } = useIsMobile();
    const variants = {
        initial: {
            x: 70,
            opacity: 0
        },
        animate: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 2,
                ease: "easeInOut"
            }
        },
    };
    return (
        <motion.div
            variants={variants}
            initial="initial"
            animate="animate"
            style={{
            ...props.style,
            backgroundColor: '#F0EDE8',
            boxShadow: "0px 4px 32px -8px rgba(172, 172, 172, 0.2)",
            borderRadius: 72,
            padding: isMobile ? '18px 16px' : '16px 40px',
            display: 'flex',
            color: 'black',
        }}>
            {
                isMobile ?
                    <MenuIcon />
                    : [
                    <div>About</div>,
                    <div style={{ width: 40}}></div>,
                    <div>How To</div>,
                    <div style={{ width: 40}}></div>,
                    <div>Product</div>,
                    <div style={{ width: 24}}></div>,
                    <div style={{ color: '#dfad77' }}>/</div>,
                    <div style={{ width: 24}}></div>,
                    <ShopIcon />,
                    <div style={{ width: 8}}></div>,
                    <div>Shop</div>,
                ]
            }
        </motion.div>
    );
}

export default  Header;